<div class="breadcrumb_block"
    *ngIf="globals.institute_details && ((globals.institute_details.role_value == 2 || globals.institute_details.role_value == 1) || ((globals.institute_details.role_value != 2 && globals.institute_details.role_value != 1)))">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <!-- <ol class="breadcrumb">
                    <li class="breadcrumb-item" [ngClass]="{'active':globals.breadcrumbs[1]==''}">
                        {{globals.breadcrumbs[0]}}
                    </li>
                    <li class="breadcrumb-item" *ngIf="globals.breadcrumbs[1]!=''"
                        [ngClass]="{'active':globals.breadcrumbs[2]==''}">
                        {{globals.breadcrumbs[1]}}
                    </li>
                    <li class="breadcrumb-item" *ngIf="globals.breadcrumbs[2]!=''"
                        [ngClass]="{'active':globals.breadcrumbs[3]!=''}">
                        {{globals.breadcrumbs[2]}}
                    </li>
                </ol> -->
                <!-- <div class="current_institute_block">
                    <ng-container *ngIf="(globals.institute_details && globals.institute_details.institute && globals.institute_details.institute.id!='')">
                        <div class="current_org me-3">
                        <b>Current Organization:</b>{{globals.institute_details.institute.institute_name}} 
                        <span *ngIf="globals.institute_details.institute.provider_id" class="id_box mb-0">{{globals.institute_details.institute.provider_id}}</span> <a routerLink="/manage/organization/update" class="edit_org" title="Edit Organization" kendoTooltip><i class="fa fa-edit"></i></a></div>
                    </ng-container>
                    <a routerLink="/landing-dashboard" class="switch_button" *ngIf="globals.authData.list.length>1">Switch Dashboard</a>
                </div> -->
                <!-- {{globals.institute_details.role_value}} -->
                <div class="select_institute_block"
                    *ngIf="(globals.institute_details && (globals.institute_details.role_value == 2 || globals.institute_details.role_value == 1)) &&  globals.institute_details.role_value != 3">
                    <ng-container *ngIf="globals.selected_institute!=null">
                        <div class="current_org me-3">
                            <b>Selected Organization:</b><span *ngIf="globals.selected_institute.provider_id"
                                class="id_box mb-0 me-2">{{globals.selected_institute.provider_id}}</span>
                            <span class="org_name">{{globals.selected_institute.institute_name}} </span>
                           
                          
                            <button
                                *ngIf="(globals.institute_details && globals.institute_details.institute && globals.institute_details.institute.id!='')"
                                kendoButton routerLink="/manage/organization/update" kendoTooltip position="bottom"
                                title="Edit" class="icon_btn grey"><i class="fa fa-pencil"></i></button>
                            <!-- <span
                                *ngIf="!selected_institute.primary_contact_id || !selected_institute.fein_number || selected_institute.institute_types == '' || selected_institute.institute_owners == ''"
                                kendoTooltip position="bottom"
                                title="Some information is missing under this Organization"
                                class="icon_btn information_icon blue me-2 ms-2"><i
                                    class="fa fa-exclamation-circle"></i></span> -->
                            <button kendoButton kendoTooltip title="Clear Selected Organization" position="bottom"
                                (click)="clearSelectedOrganization()" class="icon_btn red me-0 ms-0"><i
                                    class="fa fa-times"></i></button>
                        </div>
                    </ng-container>
                    <!-- <a *ngIf="globals.selected_institute!=null" class="switch_button" data-bs-toggle="modal" data-bs-target="#switch_org_modal"><i class="fa fa-exchange" aria-hidden="true"></i> Change Organization</a> -->
                    <!-- <a *ngIf="globals.selected_institute==null" class="switch_button" data-bs-toggle="modal" data-bs-target="#switch_org_modal"><i class="fa fa-exchange" aria-hidden="true"></i> Select Organization</a>
                    <a *ngIf="globals.selected_institute!=null" class="switch_button" data-bs-toggle="modal" data-bs-target="#switch_org_modal"><i class="fa fa-exchange" aria-hidden="true"></i> Switch Organization</a> -->
                    <a *ngIf="globals.selected_institute==null" class="switch_button"
                        (click)="SwitchOrgButtonClicked()"><i class="fa fa-exchange" aria-hidden="true"></i> Select
                        an
                        Organization</a>
                    <a *ngIf="globals.selected_institute!=null" class="switch_button"
                        (click)="SwitchOrgButtonClicked();"><i class="fa fa-exchange" aria-hidden="true"></i> Switch
                        an
                        Organization</a>
                    <!-- <a class="switch_button" (click)="select_view()"><i class="fa fa-check-square-o"
                            aria-hidden="true"></i> Select Organization</a>
                    <a class="switch_button" (click)="select_view()"><i class="fa fa-check-square-o"
                            aria-hidden="true"></i> Select Organization</a> -->
                </div>

                <div class="select_institute_block"
                    *ngIf="globals.institute_details && (globals.institute_details.role_value != 2 && globals.institute_details.role_value != 1)">
                    <ng-container *ngIf="globals.institute_details.institute!=null">
                        <!-- <div class="current_org me-3">
                            <b>Selected Organization:</b>{{globals.institute_details.institute.institute_name}}

                            <span *ngIf="globals.institute_details.institute.provider_id"
                                class="id_box mb-0">{{globals.institute_details.institute.provider_id}}</span>
                        </div> -->
                        <div *ngIf="globals.institute_details.role_value != 3 && globals.institute_details.role_value != 6" class="current_org me-3" [ngClass]="{'noborder_org': !(globals.authData.list.length>1)}">
                            <b>Selected Organization:</b><span *ngIf="globals.institute_details.institute.provider_id"
                                class="id_box mb-0 me-2">{{globals.institute_details.institute.provider_id}}</span>
                            <span class="org_name">{{globals.institute_details.institute.institute_name}} </span>
                            <button kendoButton routerLink="/manage/organization/update" title="Edit"
                                class="icon_btn grey"><i class="fa fa-pencil"></i></button>
                            <!-- <span
                                *ngIf="!selected_institute.primary_contact_id || !selected_institute.fein_number || selected_institute.institute_types == '' || selected_institute.institute_owners == ''"
                                kendoTooltip title="Missing Information"
                                class="icon_btn information_icon blue me-2 ms-2" position="bottom"><i
                                    class="fa fa-exclamation-circle"></i></span> -->
                        </div>
                    </ng-container>
                    <!-- <a class="switch_button" (click)="globals.dialogOpened = true;"><i class="fa fa-exchange"
                            aria-hidden="true"></i> Switch Organization</a> -->
                    <a class="switch_button" *ngIf="globals.authData.list.length>1" (click)="select_view()"><i
                            class="fa fa-check-square-o" aria-hidden="true"></i> Select View</a>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="clearfix"></div>
<div class="switch_view_block">
    <h5 class="float-start">Select View</h5>
    <button class="float-end close" (click)="select_view_close()" type="button"><span>×</span></button>
    <div class="clearfix"></div>

    <p class="padding_15" *ngIf="globals.institute_details.role_value!=1 && globals.institute_details.role_value!=2">
        <b>Current View:</b>
        <ng-container *ngIf="globals.institute_details.role_value==1">OESS Administrator</ng-container>
        <ng-container *ngIf="globals.institute_details.role_value==2">IFMA Administrator</ng-container>
        <ng-container *ngIf="globals.institute_details.role_value==5">Individual User</ng-container>
        <ng-container
            *ngIf="globals.institute_details.role_value!=1 && globals.institute_details.role_value!=2 && globals.institute_details.role_value!=5">
            ORGANIZATIONS</ng-container>
    </p>
    <ng-container *ngIf="OESS_Admin_present && globals.institute_details.role_value!=1">
        <p class="padding_15">
            <button class="view_btn" (click)="switchRole(1)">View as an OESS Administrator</button>
        </p>
    </ng-container>
    <ng-container *ngIf="AMSN_Admin_present && globals.institute_details.role_value!=2">
        <p class="padding_15">
            <button class="view_btn" (click)="switchRole(2)">View as an IFMA Administrator</button>
        </p>
    </ng-container>
    <!-- <ng-container *ngIf="Individual_present && globals.institute_details.role_value!=5">
        <p class="padding_15">
            <button class="view_btn" (click)="switchRole(5)">View as an Individual User</button>        
        </p>
    </ng-container>  -->

    <p class="mb-2" *ngIf="globals.institute_details.role_value==1 || globals.institute_details.role_value==2">
        <b>Recently accessed Organizations</b>
    </p>
    <p class="mb-2" *ngIf="globals.institute_details.role_value!=1 && globals.institute_details.role_value!=2"><b>Your
            Organizations</b></p>

    <div class="clearfix"></div>
    <div class="filter_block mt-2 mb-4">
        <!-- <ul>
            <li><i class="fa fa-list-ul"></i>Type</li>
            <li><i class="fa fa-user"></i>Ownership</li>
        </ul> -->
    </div>
    <div class="clearfix"></div>
    <ng-container *ngFor="let institute of Recently_instituteList; let i=index;">
        <div *ngIf="institute" class="border_line assessment_width">
            <div class="institute_block system_block pb-0">
                <div class="content_block">
                    <div class="id_box float-start me-3" *ngIf="institute.provider_id">
                        {{institute.provider_id}}</div>
                    <h5 class="float-start">{{institute.institute_name}}</h5>
                    <div class="clearfix"></div>
                    <div class="address_phone">
<!-- 
                        <p class="system_name" *ngIf="institute.institute_systems">
                            <b>System:</b>{{institute.institute_systems.system}}
                        </p>
                        <p class="system_name" *ngIf="!institute.institute_systems">
                            <b>System:</b>Not Affiliated
                        </p> -->

                        <p><i class="fa fa-map-marker"></i>
                            <span>{{institute.address1}}<br>{{institute.city}},
                                {{institute.state_abbreviation}} {{institute.zipcode}}
                            </span>
                        </p>
                        <p><i class="fa fa-phone"></i>
                            <span *ngIf="institute.phone_number">{{institute.phone_number |
                                phone}}</span>
                            <span *ngIf="!institute.phone_number">NA</span>
                        </p>

                        <!-- <p>
                            <i class="fa fa-list-ul"></i>
                            <span
                                *ngIf="institute.institute_types && institute.institute_types.display_text">{{institute.institute_types.display_text}}</span>
                            <span
                                *ngIf="!(institute.institute_types && institute.institute_types.display_text)">NA</span>
                        </p>
                        <p><i class="fa fa-user"></i>
                            <span
                                *ngIf="institute.institute_owners && institute.institute_owners.display_text">{{institute.institute_owners.display_text}}</span>
                            <span
                                *ngIf="!(institute.institute_owners && institute.institute_owners.display_text)">NA</span>
                        </p> -->
                    </div>
                    <div class="active_user_block">Divisions<br><b class="float-start">{{institute.total_divisions}}</b>
                    </div>
                    <!-- <div class="active_user_block">Units<br><b class="float-start">{{institute.total_units}}</b>
                    </div> -->
                    <div class="active_user_block mb-5">Users<br><b class="float-start">{{institute.users}}</b>
                    </div>
                    <div class="active_user_block mb-5">Cohorts<br><b class="float-start">{{institute.cohort_users}}</b>
                    </div>
                    <ng-template
                        [ngIf]="globals.selected_institute!=null && globals.selected_institute!='' && institute.id==globals.selected_institute.id"
                        [ngIfElse]="noSelectedFound">
                        <div class="selected_btn"><span>Currently Selected</span></div>
                    </ng-template>
                    <ng-template #noSelectedFound>
                        <div class="selected_btn"><button class="all_btn theme_btn"
                                (click)="switchOrg(institute)">Select</button></div>
                    </ng-template>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </ng-container>

    <p *ngIf="Recently_instituteList.length==0">To select the organization <button class="view_btn"
            (click)="globals.dialogOpened = true;">Click here</button></p>
    <p *ngIf="instituteList.length>4 && Recently_instituteList.length>0">To select the different organization <button
            class="view_btn" (click)="globals.dialogOpened = true;">Click here</button></p>
</div>



<!-- <div class="switch_view_block">
    <h5 class="float-start">Select View</h5>
    <button class="float-end close"  (click)="select_view_close()" type="button"><span>×</span></button>
    <div class="clearfix"></div>
    <p class="padding_15"><b>Current View:</b>ORGANIZATIONS | <button class="view_btn">View as an Individual</button></p>

    <p class="mb-2"><b>Recently accessed Organizations</b></p>
    <div class="border_line assessment_width">
        <div class="institute_block system_block pb-0">
            <div class="content_block">
                <div class="id_box float-start me-3">234561</div>
                <h5 class="float-start">ABBEVILLE GENERAL HOSPITAL</h5>
                <div class="clearfix"></div>
                <div class="address_phone">
                   
                    <p class="system_name">
                        <b>System:</b>Kaiser Permanente Southern California
                    </p>
                    <p><i class="fa fa-map-marker"></i>
                        <span>address1<br>city, AF 123456
                        </span>
                    </p>
                    <p><i class="fa fa-phone"></i>
                        <span>1234567890</span>
                    </p>
                    <p>
                        <i class="fa fa-list-ul"></i>
                        <span>Critical Access Hospitals</span>
                    </p>
                    <p><i class="fa fa-user"></i>
                        <span>Government - Hospital District or Authority</span>
                    </p>
                </div>
                <div class="active_user_block">Divisions<br><b class="float-start">442</b></div>
                <div class="active_user_block">Units<br><b class="float-start">114</b>
                </div>
                <div class="active_user_block mb-5">Users<br><b class="float-start">453</b>
                </div>
                <div class="selected_btn"><span>Currently Selected</span></div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <div class="border_line assessment_width">
        <div class="institute_block system_block pb-0">
            <div class="content_block">
                <div class="id_box float-start me-3">234561</div>
                <h5 class="float-start">ABRAZO CENTRAL CAMPUS</h5>
                <div class="clearfix"></div>
                <div class="address_phone">
                   
                    <p class="system_name">
                        <b>System:</b>institute_systems
                    </p>
                    <p><i class="fa fa-map-marker"></i>
                        <span>address1<br>city, AF 123456
                        </span>
                    </p>
                    <p><i class="fa fa-phone"></i>
                        <span>1234567890</span>
                    </p>
                    <p>
                        <i class="fa fa-list-ul"></i>
                        <span>institute_types</span>
                    </p>
                    <p><i class="fa fa-user"></i>
                        <span>institute_owners</span>
                    </p>
                </div>
                <div class="active_user_block">Divisions<br><b class="float-start">442</b></div>
                <div class="active_user_block">Units<br><b class="float-start">114</b>
                </div>
                <div class="active_user_block mb-5">Users<br><b class="float-start">453</b>
                </div>
               
                <div class="selected_btn"><button class="all_btn theme_btn">Select</button></div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <div class="border_line assessment_width">
        <div class="institute_block system_block pb-0">
            <div class="content_block">
                <div class="id_box float-start me-3">234561</div>
                <h5 class="float-start">ABRAHAM LINCOLN MEMORIAL HOSPITAL</h5>
                <div class="clearfix"></div>
                <div class="address_phone">
                    <p class="system_name">
                        <b>System:</b>institute_systems
                    </p>
                    <p><i class="fa fa-map-marker"></i>
                        <span>address1<br>city, AF 123456
                        </span>
                    </p>
                    <p><i class="fa fa-phone"></i>
                        <span>1234567890</span>
                    </p>
                    <p>
                        <i class="fa fa-list-ul"></i>
                        <span>institute_types</span>
                    </p>
                    <p><i class="fa fa-user"></i>
                        <span>institute_owners</span>
                    </p>
                </div>
                <div class="active_user_block">Divisions<br><b class="float-start">442</b></div>
                <div class="active_user_block">Units<br><b class="float-start">114</b>
                </div>
                <div class="active_user_block mb-5">Users<br><b class="float-start">453</b>
                </div>
                
                <div class="selected_btn"><button class="all_btn theme_btn">Select</button></div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <p>To select the different organization <button class="view_btn" (click)="globals.dialogOpened = true;">Click here</button></p>
</div> -->


<kendo-dialog [minWidth]="550" [width]="550" *ngIf="globals.dialogOpened" (close)="close();">
    <kendo-dialog-titlebar>
        <h5 *ngIf="globals.selected_institute==null" class="modal-title" id="switch_org_modalLabel">Select an
            Organization</h5>
        <h5 *ngIf="globals.selected_institute!=null" class="modal-title" id="switch_org_modalLabel">Switch an
            Organization</h5>
    </kendo-dialog-titlebar>
    <div kendoDialogContainer>

        <p class="padding_15" *ngIf="globals.selected_institute==null">You must select an organization to view the
            specific data. Below is the list of organizations that you are affiliated with. Please select one from the
            list below.</p>
        <p class="padding_15" *ngIf="globals.selected_institute!=null">Below is the list of organizations that you are
            affiliated with. you can switch an organization from the list below.</p>

        <kendo-formfield [ngClass]="{'k-form-field-error': orgError}">
            <label><span>*</span> Organization</label>
            <kendo-dropdownlist id="institute_id" [(ngModel)]="institute_id" [data]="instituteList"
                [textField]="'institute_name'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="true"
                (filterChange)="instituteFilter($event)" (selectionChange)="filterData($event)" (ngModelChange)="onInstituteChange($event)"
               >
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span title="{{ dataItem.institute_name }}">{{ dataItem.institute_name
                        }}</span>
                </ng-template>
                <ng-template kendoMultiSelectNoDataTemplate>
                    <h6>NO ORGANIZATION FOUND</h6>
                </ng-template>
            </kendo-dropdownlist>
            <div class="k-form-error" *ngIf="orgError">
                Please select an organization.
            </div>
            <div class="k-form-error" *ngIf="btnDisabled">
               This Organization is Deactivated!
            </div>
        </kendo-formfield>

        <p class="mb-2" *ngIf="selected_institute!=null"><b>Selected Organization</b></p>
        <div class="filter_block mt-2 mb-4" *ngIf="selected_institute!=null">
            <!-- <ul>
                <li><i class="fa fa-list-ul"></i>Type</li>
                <li><i class="fa fa-user"></i>Ownership</li>
            </ul> -->
        </div>
        <div class="clearfix"></div>
        <div class="border_line assessment_width" *ngIf="selected_institute!=null">
            <div class="institute_block pb-0">
                <div class="content_block">
                    <div class="address_phone">
                        <div class="id_box float-start me-3" *ngIf="selected_institute.provider_id!=null">
                            {{selected_institute.provider_id}}</div>
                        <h5 class="float-start">{{selected_institute.institute_name}}</h5>
                        <div class="clearfix"></div>
                        <!-- <p class="system_name" *ngIf="selected_institute.institute_systems">
                            <b>System:</b>{{selected_institute.institute_systems.system}}
                        </p>
                        <p class="system_name" *ngIf="!selected_institute.institute_systems">
                            <b>System:</b>Not Affiliated
                        </p> -->
                        <p><i class="fa fa-map-marker"></i>
                            <span>{{selected_institute.address1}}<br>{{selected_institute.city}},
                                <!-- <ng-container *ngIf="institute.county!=null && institute.county!=''">, {{institute.county}}</ng-container> -->
                                {{selected_institute.state_abbreviation}} {{selected_institute.zipcode}}
                            </span>
                        </p>
                        <p><i class="fa fa-phone"></i>
                            <span *ngIf="selected_institute.phone_number">{{selected_institute.phone_number |
                                phone}}</span>
                            <span *ngIf="!selected_institute.phone_number">NA</span>
                        </p>
                    </div>
                    <div class="active_user_block">
                        Divisions<b>{{selected_institute.total_divisions}}</b></div>
                    <!-- <div class="active_user_block">Units<b>{{selected_institute.total_units}}</b>
                    </div> -->
                    <div class="active_user_block">Users<b>{{selected_institute.users}}</b>
                    </div>
                    <div class="active_user_block">Cohorts<b>{{selected_institute.cohort_users}}</b>
                    </div>
                    <!-- <p>
                        <i class="fa fa-list-ul"></i>
                        <span
                            *ngIf="selected_institute.institute_types && selected_institute.institute_types.display_text">{{selected_institute.institute_types.display_text}}</span>
                        <span
                            *ngIf="!(selected_institute.institute_types && selected_institute.institute_types.display_text)">NA</span>
                    </p> -->
                    <!-- <span
                            *ngIf="!(selected_institute.institute_types && selected_institute.institute_types.display_text)">NA</span><span
                            class="no_border d-none d-xxl-block"><i class="fa fa-user"></i>
                            <span class="no_border"
                                *ngIf="selected_institute.institute_owners && selected_institute.institute_owners.display_text">{{selected_institute.institute_owners.display_text}}</span>
                            <span class="no_border"
                                *ngIf="!(selected_institute.institute_owners && selected_institute.institute_owners.display_text)">NA</span>
                        </span> -->


                    <!-- <p><i class="fa fa-user"></i>
                        <span
                            *ngIf="selected_institute.institute_owners && selected_institute.institute_owners.display_text">{{selected_institute.institute_owners.display_text}}</span>
                        <span
                            *ngIf="!(selected_institute.institute_owners && selected_institute.institute_owners.display_text)">NA</span>
                    </p> -->
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <div class="footer_button text-end">
            <button *ngIf="globals.selected_institute==null" type="button" class="all_btn theme_btn"
                (click)="selectOrg()" [disabled]="btnDisabled">Select</button>
            <button *ngIf="globals.selected_institute!=null" type="button" class="all_btn theme_btn"
                (click)="selectOrg()" [disabled]="btnDisabled">Switch</button>
        </div>

    </div>
    <!-- <kendo-dialog-actions>
                <button kendoButton (click)="close('no')">No</button>
                <button kendoButton (click)="close('yes')" primary="true">Yes</button>
            </kendo-dialog-actions> -->
</kendo-dialog>
<div class="clearfix"></div>
<!-- Modal -->
<div class="modal fade" id="switch_org_modal" tabindex="-1" aria-labelledby="switch_org_modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 *ngIf="globals.selected_institute==null" class="modal-title" id="switch_org_modalLabel">Select
                    Organization</h5>
                <h5 *ngIf="globals.selected_institute!=null" class="modal-title" id="switch_org_modalLabel">Switch
                    Organization</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p class="padding_15">Below is the list of organizations that you are affiliated with. you can
                    select/switch an organization from the list below.</p>

                <kendo-formfield [ngClass]="{'k-form-field-error': orgError}">
                    <label><span>*</span> Organization</label>
                    <kendo-dropdownlist id="institute_id" [(ngModel)]="institute_id" [data]="instituteList"
                        [textField]="'institute_name'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="false"
                        (filterChange)="instituteFilter($event)" (selectionChange)="filterData($event)"
                       >
                        <ng-template kendoDropDownListItemTemplate let-dataItem>
                            <span title="{{ dataItem.institute_name }}">{{ dataItem.institute_name
                                }}</span>
                        </ng-template>
                        <ng-template kendoMultiSelectNoDataTemplate>
                            <h6>NO ORGANIZATION FOUND</h6>
                        </ng-template>
                    </kendo-dropdownlist>
                    <div class="k-form-error" *ngIf="orgError">
                        Please select an organization.
                    </div>
                </kendo-formfield>

                <p class="padding_15" *ngIf="selected_institute!=null"><b>Selected Organization</b></p>
                <div class="border_line assessment_width" *ngIf="selected_institute!=null">
                    <div class="institute_block pb-0">
                        <div class="content_block">
                            <div class="address_phone">
                                <div class="id_box float-start me-3" *ngIf="selected_institute.provider_id!=null">
                                    {{selected_institute.provider_id}}</div>
                                <h5 class="float-start">{{selected_institute.institute_name}}</h5>
                                <div class="clearfix"></div>
                                <!-- <p class="system_name"
                                    *ngIf="selected_institute.institute_systems && selected_institute.institute_systems.system">
                                    <b>System:</b>{{selected_institute.institute_systems.system}}
                                </p>
                                <p class="system_name"
                                    *ngIf="!(selected_institute.institute_systems && selected_institute.institute_systems.system)">
                                    <b>System:</b>Not Affiliated
                                </p> -->
                                <p><i class="fa fa-map-marker"></i>
                                    <span>{{selected_institute.address1}}<br>{{selected_institute.city}},
                                        <!-- <ng-container *ngIf="institute.county!=null && institute.county!=''">, {{institute.county}}</ng-container> -->
                                        {{selected_institute.state_abbreviation}} {{selected_institute.zipcode}}
                                    </span>
                                </p>
                                <p><i class="fa fa-phone"></i>
                                    <span *ngIf="selected_institute.phone_number">{{selected_institute.phone_number |
                                        phone}}</span>
                                    <span *ngIf="!selected_institute.phone_number">NA</span>
                                </p>
                                <!-- <p><i class="fa fa-list-ul"></i>
                                    <span
                                        *ngIf="selected_institute.institute_types && selected_institute.institute_types.display_text">{{selected_institute.institute_types.display_text}}</span>
                                    <span
                                        *ngIf="!(selected_institute.institute_types && selected_institute.institute_types.display_text)">NA</span> -->
                                    <!-- <span
                                        class="no_border d-none d-xxl-block"><i class="fa fa-user"></i>
                                        <span
                                            *ngIf="selected_institute.institute_owners && selected_institute.institute_owners.display_text"
                                            class="no_border">{{selected_institute.institute_owners.display_text}}</span>
                                        <span
                                            *ngIf="!(selected_institute.institute_owners && selected_institute.institute_owners.display_text)"
                                            class="no_border">NA</span>
                                    </span> -->
                                <!-- </p> -->
                                <!-- <p><i class="fa fa-user"></i>
                                    <span
                                        *ngIf="selected_institute.institute_owners && selected_institute.institute_owners.display_text">{{selected_institute.institute_owners.display_text}}</span>
                                    <span
                                        *ngIf="!(selected_institute.institute_owners && selected_institute.institute_owners.display_text)">NA</span>
                                </p> -->
                            </div>
                            <div class="active_user_block">
                                Divisions<b>{{selected_institute.total_divisions}}</b></div>
                            <!-- <div class="active_user_block">Units<b>{{selected_institute.total_units}}</b>
                            </div> -->
                            <div class="active_user_block">Users<b>{{selected_institute.users}}</b>
                            </div>
                            <div class="active_user_block">Cohorts<b>{{selected_institute.cohort_users}}</b>
                            </div>

                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button *ngIf="globals.selected_institute==null" type="button" class="all_btn theme_btn"
                    (click)="selectOrg()" [disabled]="btnDisabled">Select</button>
                <button *ngIf="globals.selected_institute!=null" type="button" class="all_btn theme_btn"
                    (click)="selectOrg()" [disabled]="btnDisabled">Switch</button>
                <button type="button" class="all_btn themeoption_btn" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- <kendo-dialog [minWidth]="550" [width]="550"
    [class.hide-icon]="(!(!globals.switchPrimaryContactModel && selected_institute.primary_contact_id)) && (globals.institute_details.role_value != 2 && globals.institute_details.role_value != 1)"
    *ngIf="globals.selectPrimaryContactModel" (close)="cancelPrimaryContact();"> -->

    <!-- old condition - globals.selectPrimaryContactModel -->
<kendo-dialog [minWidth]="550" [width]="550" *ngIf="false"
    (close)="cancelPrimaryContact();">
    <kendo-dialog-titlebar>
        <h5 *ngIf="!globals.switchPrimaryContactModel && !globals.selectPrimaryContactOnlyModel" class="modal-title"
            id="switch_org_modalLabel">Update
            Organization Information</h5>
        <h5 *ngIf="globals.switchPrimaryContactModel" class="modal-title" id="switch_org_modalLabel">Switch Primary
            Contact</h5>
        <h5 *ngIf="!globals.switchPrimaryContactModel && globals.selectPrimaryContactOnlyModel" class="modal-title"
            id="switch_org_modalLabel">Select
            Primary Contact</h5>
    </kendo-dialog-titlebar>
    <div kendoDialogContainer>

        <!-- <p *ngIf="!globals.switchPrimaryContactModel && !globals.selectPrimaryContactOnlyModel" class="padding_15">This
            is the placeholder for Instructions about
            Update Organization Information.
        </p> -->

        <p *ngIf="globals.switchPrimaryContactModel" class="padding_15">You may assign a Primary Contact role to a new
            user by assigning a different role to the existing Primary Contact and selecting a new user to become
            Primary Contact.
        </p>

        <!-- <p *ngIf="!globals.switchPrimaryContactModel && globals.selectPrimaryContactOnlyModel" class="padding_15">This
            is the placeholder for Instructions about
            Select Organization Primary Contact.
        </p> -->

        <!-- <p class="mb-2" *ngIf="globals.switchPrimaryContactModel"><b>Current Primary Contact: </b>
            {{existingPrimaryContact.display_text}}</p> -->

        <kendo-formfield *ngIf="globals.switchPrimaryContactModel"
            [ngClass]="{'k-form-field-error': primaryContactRoleError}">
            <label><span>*</span> Select new role for {{selected_institute.primary_contact}}</label>
            <kendo-dropdownlist id="existing_user_role_id" [(ngModel)]="existing_user_role_id" [data]="roleList"
                [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="true"
                (filterChange)="roleFilter($event)" (selectionChange)="primaryContactRoleError = false">
                <ng-template kendoMultiSelectNoDataTemplate>
                    <h6>NO ROLE FOUND</h6>
                </ng-template>
            </kendo-dropdownlist>
            <div class="k-form-error" *ngIf="primaryContactRoleError">
                Please select role.
            </div>
        </kendo-formfield>

        
        <kendo-formfield *ngIf="globals.switchPrimaryContactModel || !selected_institute.primary_contact_id"
            [ngClass]="{'k-form-field-error': primaryContactError}">
            <label *ngIf="!selected_institute.primary_contact_id"><span>*</span> Select Primary Contact</label>
            <label *ngIf="selected_institute.primary_contact_id"><span>*</span> Select new Primary Contact</label>
            <kendo-dropdownlist id="primary_user_id" [(ngModel)]="primary_user_id" [data]="userList"
                [textField]="'display_text'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="true"
                (filterChange)="instituteUserFilter($event)" (selectionChange)="primaryContactError = false"
                >
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span title="{{ dataItem.display_text }}">{{ dataItem.display_text
                        }}</span>
                </ng-template>
                <ng-template kendoMultiSelectNoDataTemplate>
                    <h6>NO USER FOUND</h6>
                </ng-template>
            </kendo-dropdownlist>
            <div class="k-form-error" *ngIf="primaryContactError">
                Please select primary contact user.
            </div>
        </kendo-formfield>

        <kendo-formfield
            *ngIf="!selected_institute.fein_number && !globals.switchPrimaryContactModel && !globals.selectPrimaryContactOnlyModel"
            [ngClass]="{'k-form-field-error': fein_number_errors.required || fein_number_errors.pattern}">
            <label> Last 6 Digits of FEIN</label>
            <input [(ngModel)]="fein_number" maxlength="6" kendoTextBox class="form-control"
                (input)="checkFeinNumber()" />
            <div class="k-form-error" *ngIf="fein_number_errors.required">
                Please enter FEIN.
            </div>
            <div class="k-form-error" *ngIf="fein_number_errors.pattern">
                Please enter valid FEIN.
            </div>
        </kendo-formfield>

        <kendo-formfield
            *ngIf="selected_institute.institute_types == '' && !globals.switchPrimaryContactModel && !globals.selectPrimaryContactOnlyModel">
            <label> Type</label>
            <kendo-dropdownlist id="institute_type_id" [(ngModel)]="institute_type_id" [data]="instituteTypeList"
                [textField]="'display_text'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="true"
                (filterChange)="instituteTypeFilter($event)">
                <ng-template kendoMultiSelectNoDataTemplate>
                    <h6>NO TYPE FOUND</h6>
                </ng-template>
            </kendo-dropdownlist>
        </kendo-formfield>

        <!-- <kendo-formfield
            *ngIf="selected_institute.institute_owners == '' && !globals.switchPrimaryContactModel && !globals.selectPrimaryContactOnlyModel">
            <label> Ownership</label>
            <kendo-dropdownlist id="institute_ownership_id" [(ngModel)]="institute_ownership_id"
                [data]="instituteOwnershipList" [textField]="'display_text'" [valueField]="'id'" [valuePrimitive]="true"
                [filterable]="true" (filterChange)="instituteOwnershipFilter($event)" [virtual]="globals.virtual">
                <ng-template kendoMultiSelectNoDataTemplate>
                    <h6>NO OWNERSHIP FOUND</h6>
                </ng-template>
            </kendo-dropdownlist>
        </kendo-formfield> -->

        <!-- <kendo-formfield
            *ngIf="selected_institute.institute_systems == '' && !globals.switchPrimaryContactModel && !globals.selectPrimaryContactOnlyModel">
            <label> Are you affiliate with any System? if yes then select system.</label>
            <kendo-dropdownlist id="institute_system_id" [(ngModel)]="institute_system_id" [data]="systemList"
                [textField]="'system'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="true"
                (filterChange)="systemFilter($event)" [virtual]="globals.virtual"
                (selectionChange)="selectSystem($event)">
                <ng-template kendoMultiSelectNoDataTemplate>
                    <h6>NO SYSTEM FOUND</h6>
                </ng-template>
            </kendo-dropdownlist>
        </kendo-formfield> -->

        <!-- <kendo-formfield *ngIf="is_other_system"
            [ngClass]="{'k-form-field-error': other_system_errors.required && other_system_errors.minlength && system_already_in_list}">
            <label><span>*</span> Specify your System</label>
            <input [(ngModel)]="institute_other_system" maxlength="100" kendoTextBox class="form-control"
                (input)="checkExistSystem()" />
            <div class="k-form-error" *ngIf="other_system_errors.required">
                Please enter system.
            </div>
            <div class="k-form-error" *ngIf="other_system_errors.minlength">
                System must be at least 2 characters long.
            </div>
            <div class="k-form-error" *ngIf="system_already_in_list">
                System is already available in list.
            </div>
        </kendo-formfield> -->

        <div class="footer_button float-start"
            *ngIf="!globals.switchPrimaryContactModel && selected_institute.primary_contact_id">
            <button type="button" class="all_btn themeoption_btn" (click)="closePrimaryContact()">Skip</button>
        </div>

        <div class="footer_button float-end">
            <button *ngIf="!globals.switchPrimaryContactModel && !globals.selectPrimaryContactOnlyModel" type="button"
                class="all_btn theme_btn" (click)="selectPrimaryContact()" [disabled]="btnDisabled">Update</button>

            <button *ngIf="globals.switchPrimaryContactModel" type="button" class="all_btn theme_btn"
                (click)="selectPrimaryContact()" [disabled]="btnDisabled">Switch</button>

            <button *ngIf="!globals.switchPrimaryContactModel && globals.selectPrimaryContactOnlyModel" type="button"
                class="all_btn theme_btn" (click)="selectPrimaryContact()" [disabled]="btnDisabled">Select</button>
        </div>

    </div>
    <!-- <kendo-dialog-actions>
                <button kendoButton (click)="close('no')">No</button>
                <button kendoButton (click)="close('yes')" primary="true">Yes</button>
            </kendo-dialog-actions> -->
</kendo-dialog>