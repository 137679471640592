import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ConfigurationService } from '../app-configuration/services/configuration.service';
import { RegistrationService } from '../services/registration.service';
import { SubscriptionService } from '../app-manage/services/subscription.service';
import { SystemService } from '../app-manage/services/system.service';
import { CookieService } from 'ngx-cookie-service';
declare var $, Swal: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  registerForm: UntypedFormGroup;

  stateList = [];
  stateListFilterData: any;
  countyList = [{ id: '', name: 'Select State First' }];
  countyListFilterData: any;
  role = 1;
  instituteOwnershipList = [];
  instituteOwnershipListFilterData: any;
  instituteTypeList = [];
  instituteTypeListFilterData: any;
  systemList = [];
  systemListFilterData: any;
  instituteInfo: any = {};
  personalInfo: any = {};
  subscriptionInfo: any = {};
  system_already_in_list: boolean = false;
  is_other_system: boolean = false;
  defaultCurrentDate: Date = new Date();

  constructor(
    public globals: Globals,
    private router: Router,
    private AuthService: AuthService,
    private ConfigurationService: ConfigurationService,
    private RegistrationService: RegistrationService,
    private subscriptionService: SubscriptionService,
    private fb: UntypedFormBuilder,
    private CookieService: CookieService,
    private SystemService: SystemService
  ) {}

  ngOnInit(): void {
    this.globals.isLoading = false;
    this.subscriptionExpiryDate = this.getTomorrowDate()
    this.globals.breadcrumbs = ['Organization Registration', '', ''];
    this.registerForm = this.fb.group({
      //role: ['', [Validators.required]],
      subscriptionInfo: this.fb.group({
        subscription_number: [''],
        subscription_order_id: ['',Validators.pattern('^[a-zA-Z\\d@_-]+$')],
        expiry_date: [''],
       
      }),
      personalInfo: this.fb.group({
        first_name: [''],
        last_name: [''],
        // first_name: [
        //   '',
        //   [Validators.required, Validators.pattern("[a-zA-Z',&.]{2,}")],
        // ],
        // last_name: [
        //  '',
        //   [Validators.required, Validators.pattern("[a-zA-Z',&.]{2,}")],
        // ],
        // email_address: [
        //   [''],
        //    [
        //      Validators.required,
        //      Validators.pattern(
        //        '^[a-zA-Z]{1}[a-zA-Z0-9.-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,4}$'
        //      ),
        //    ],
        //  ],
        email_address: [''],
        //title: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9]+[A-Za-z0-9 ]+$')]],
      }),
      checkOrg: this.fb.group({
        institute_name: [
          '',
          [
            Validators.required,
            Validators.pattern("^[A-Za-z0-9',&.]+[A-Za-z0-9',&. ]+$"),
          ],
        ],
        fein_number: [''],
        // fein_number: [
        //   '',
        //   [Validators.required, Validators.pattern('[0-9]{6,6}')],
        // ],
        state_id: ['', [Validators.required]],
      }),
      instituteInfo: this.fb.group({
        provider_id: ['', [Validators.pattern('[0-9]{2,}')]],
        registered_date: [null],
        website: [
          '',
          [
  
            Validators.pattern(
              '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
            ),
          ],
        ],
        order_id: ['', [Validators.required, Validators.maxLength(20),noWhitespaceValidator(),Validators.pattern('^[a-zA-Z\\d@_-]+$')]],
        is_active: [1],
        formated_phone_number: [
          '',
          [Validators.required, Validators.pattern('[0-9-. ()+]{14}')],
        ],
        system_id: [''],
        other_system: [''],
        address1: ['', [Validators.required, Validators.minLength(5),noWhitespaceValidator()]],
        address2: [''],
        address3: [''],
        zipcode: [
          '',
          [Validators.required, Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$')],
        ],
        city: [
          '',
          [
            Validators.required,
            Validators.pattern("^[a-zA-Z\ '-.]*$"),
            noWhitespaceValidator()
          ],
        ],
        county_id: ['', [Validators.required]],
        institute_type_id: [''],
        institute_ownership_id: [''],
        emergency_services: [0],
      }),
    });

    // this.registerForm.controls.personalInfo.get('first_name').disable();
    // this.registerForm.controls.personalInfo.get('last_name').disable();
    // this.registerForm.controls.personalInfo.get('email_address').disable();

    this.disableField(true, 'county_id');
    this.getStates();
    this.getSystems();
    this.getConfigurationData();
    this.getSubscriptionExpiryDurationDays();
  }

  getStates() {
    this.RegistrationService.getActiveState().then(
      (data) => {
        var stateList = data['data'];
        var defaultItem =
          stateList.length > 0
            ? { id: '', name: 'Select State' }
            : { id: '', name: 'No State Found!' };
        this.stateList.push(defaultItem);
        this.stateList = [...this.stateList, ...stateList];
        this.stateListFilterData = this.stateList;
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getSystems() {
    this.SystemService.getActiveSystem().then(
      (data) => {
        let systemList = data['data'];
        var defaultItem =
          systemList.length > 0
            ? { id: '', system: 'Select System' }
            : { id: '', system: 'No System Found!' };
        this.systemList.push(defaultItem);
        this.systemList = [...this.systemList, ...systemList];
        // var otherItem = { id: 'other_system', system: 'Other' };
        // this.systemList.push(otherItem);
        this.systemListFilterData = this.systemList;
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  selectState(item) {
    if (item.id != '') {
      this.instituteInfo.state = item.name;
    } else {
      this.instituteInfo.state = null;
    }
  }
  selectCounty(item) {
    if (item.id != '') {
      this.instituteInfo.county = item.name;
    } else {
      this.instituteInfo.county = null;
    }
  }
  selectInstituteType(item) {
    if (item.id != '') {
      this.instituteInfo.institute_type = item.display_text;
    } else {
      this.instituteInfo.institute_type = null;
    }
  }
  selectInstituteOwnership(item) {
    if (item.id != '') {
      this.instituteInfo.institute_ownership = item.display_text;
    } else {
      this.instituteInfo.institute_ownership = null;
    }
  }

  selectSystem(item) {
    if (item.id != '') {
      this.instituteInfo.system = item.system;
      if (this.instituteInfo.system == 'Other') {
        this.is_other_system = true;
        this.registerForm.controls.instituteInfo[
          'controls'
        ].other_system.patchValue('');
        this.registerForm.controls.instituteInfo[
          'controls'
        ].other_system.setValidators([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(200),
        ]);
        this.registerForm.controls.instituteInfo[
          'controls'
        ].other_system.updateValueAndValidity();
      } else {
        this.is_other_system = false;
        this.registerForm.controls.instituteInfo[
          'controls'
        ].other_system.patchValue('');
        this.registerForm.controls.instituteInfo[
          'controls'
        ].other_system.clearValidators();
        this.registerForm.controls.instituteInfo[
          'controls'
        ].other_system.updateValueAndValidity();
        this.system_already_in_list = false;
      }
    } else {
      this.is_other_system = false;
      this.registerForm.controls.instituteInfo[
        'controls'
      ].other_system.patchValue('');
      this.registerForm.controls.instituteInfo[
        'controls'
      ].other_system.clearValidators();
      this.registerForm.controls.instituteInfo[
        'controls'
      ].other_system.updateValueAndValidity();
      this.system_already_in_list = false;
      this.instituteInfo.system = null;
    }
  }

  checkExistSystem() {
    var system =
      this.registerForm.controls.instituteInfo[
        'controls'
      ].other_system.value.toLowerCase();
    if (
      system != 'other' &&
      this.systemListFilterData.some(
        (obj) => obj.system.toLowerCase().trim() == system.trim()
      )
    ) {
      this.system_already_in_list = true;
    } else {
      this.instituteInfo.other_system =
        this.registerForm.controls.instituteInfo[
          'controls'
        ].other_system.value.toLowerCase();
      this.system_already_in_list = false;
    }
  }

  getConfigurationData() {
    this.ConfigurationService.getAll({
      key: ['InstituteTypes', 'InstituteOwnership'],
      status: '1',
    }).then(
      (data) => {
        var instituteTypeList = data['data']['InstituteTypes'];
        var defaultItem =
          instituteTypeList.length > 0
            ? { id: '', display_text: 'Select a type' }
            : { id: '', display_text: 'No Institute Type Found!' };
        this.instituteTypeList.push(defaultItem);
        this.instituteTypeList = [
          ...this.instituteTypeList,
          ...instituteTypeList,
        ];
        this.instituteTypeListFilterData = this.instituteTypeList;

        var instituteOwnershipList = data['data']['InstituteOwnership'];
        var defaultItem =
          instituteOwnershipList.length > 0
            ? { id: '', display_text: 'Select an ownership' }
            : { id: '', display_text: 'No Institute Ownership Found!' };
        this.instituteOwnershipList.push(defaultItem);
        this.instituteOwnershipList = [
          ...this.instituteOwnershipList,
          ...instituteOwnershipList,
        ];
        this.instituteOwnershipListFilterData = this.instituteOwnershipList;
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getCounties(id) {
    this.registerForm.controls.instituteInfo.patchValue({ county_id: '' });
    this.instituteInfo.county = null;
    if (id > 0) {
      this.RegistrationService.getCountyByState(id).then(
        (data) => {
          this.countyList = [];
          var countyList = data['data'];
          var defaultItem =
            countyList.length > 0
              ? { id: '', name: 'Select County' }
              : { id: '', name: 'No County Found!' };
          if (countyList.length > 0) {
            this.disableField(false, 'county_id');
          } else {
            this.disableField(true, 'county_id');
          }
          this.countyList.push(defaultItem);
          this.countyList = [...this.countyList, ...countyList];
          this.countyListFilterData = this.countyList;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    } else {
      this.disableField(true, 'county_id');
      this.countyList = [{ id: '', name: 'Select State First' }];
    }
  }

  // roleSubmit() {
  //   this.role = this.registerForm.value.role;
  //   $("#role").removeClass("show active")
  //   $("#personalinfo").addClass("show active");
  //   $("#role-tab").removeClass("active");
  //   $("#role-tab").addClass("complete");
  //   $("#personalinfo-tab").addClass("active");
  // }

  checkOrgSubmit() {
    this.registerForm.controls.checkOrg.markAllAsTouched();
    if (this.registerForm.controls.checkOrg.valid) {
      this.instituteInfo = {
        ...this.instituteInfo,
        ...this.registerForm.value.checkOrg,
      };

      this.globals.isLoading = true;
      this.RegistrationService.checkOrganization(this.instituteInfo).then(
        (data) => {
          this.globals.isLoading = false;
          $('#checkOrg').removeClass('show active');
          $('#institute').addClass('show active');
          $('#checkOrg-tab').removeClass('active');
          $('#checkOrg-tab').addClass('complete');
          $('#institute-tab').removeClass('complete');
          $('#institute-tab').addClass('active');
          $('#personalinfo').removeClass('show active');
          $('#personalinfo-tab').removeClass('active');
          $('#confirm').removeClass('show active');
          $('#confirm-tab').removeClass('active');
          $('#cohortinfo-tab').removeClass('active');
          $('#cohortinfo-tab').removeClass('complete');
          $('#cohortinfo').removeClass('show active');
        },
        (error) => {
          this.globals.isLoading = false;
          if (error.error.code == 422) {
            if (error.error.message != undefined) {
              this.globals.sweetAlert(
                'warning',
                'Organization Exist',
                'This Organization is already exist.'
              );
            }
          } else {
            this.globals.errorSweetAlert();
          }
        }
      );
    }
  }

  instituteInfoPrevious() {
    $('#institute').removeClass('show active');
    $('#checkOrg').addClass('show active');
    $('#institute-tab').removeClass('active');
    $('#checkOrg-tab').removeClass('complete');
    $('#checkOrg-tab').addClass('active');
    $('#personalinfo').removeClass('show active');
    $('#cohortinfo').removeClass('show active');
    $('#personalinfo-tab').removeClass('active');
    $('#confirm').removeClass('show active');
    $('#confirm-tab').removeClass('active');
    $('#cohortinfo-tab').removeClass('active');
    $('#cohortinfo-tab').removeClass('complete');
    $('#cohortinfo').removeClass('show active');

  }

  instituteInfoSubmit() {
    if (
      this.registerForm.controls.checkOrg.valid &&
      this.registerForm.controls.instituteInfo.valid
    ) {
      this.instituteInfo = {
        ...this.instituteInfo,
        ...this.registerForm.value.instituteInfo,
      };
      this.instituteInfo.is_active =
        this.instituteInfo.is_active == true ||
        this.instituteInfo.is_active == 1
          ? 1
          : 0;
      this.instituteInfo.emergency_services =
        this.instituteInfo.emergency_services == true ||
        this.instituteInfo.emergency_services == 1
          ? 1
          : 0;
      $('#institute').removeClass('show active');
      $('#personalinfo').addClass('show active');
      $('#institute-tab').removeClass('active');
      $('#institute-tab').addClass('complete');
      $('#cohortinfo').removeClass('show active');

      $('#personalinfo-tab').removeClass('complete');
      $('#personalinfo-tab').addClass('active');
      $('#checkOrg').removeClass('show active');
      $('#checkOrg-tab').removeClass('active');
      $('#checkOrg-tab').addClass('complete');
      $('#confirm').removeClass('show active');
      $('#confirm-tab').removeClass('active');

      this.personalInfoSubmit();
    }
  }

  cohortInfoSubmit(){
    this.registerForm.controls.instituteInfo.markAllAsTouched();
    this.registerForm.controls.checkOrg.markAllAsTouched();

    if(this.registerForm.controls.instituteInfo.valid && this.registerForm.controls.checkOrg.valid){

      $('#checkOrg').removeClass('show active');
      $('#cohortinfo').addClass('show active');
      $('#checkOrg-tab').removeClass('active');
      $('#checkOrg-tab').addClass('complete');
      $('#institute-tab').removeClass('show active')
      $('#institute').removeClass('show active')
      $('#institute-tab').addClass('complete')
      $('#cohortinfo-tab').removeClass('complete');
      $('#cohortinfo-tab').addClass('active');
      $('#personalinfo').removeClass('show active');
      $('#personalinfo-tab').removeClass('active');
      $('#confirm').removeClass('show active');
      $('#confirm-tab').removeClass('active');
      $('#subscriptioninfo').removeClass('show active');
      $('#subscriptioninfo-tab').removeClass('complete');
      $('#subscriptioninfo-tab').removeClass('active');
    }
  
  }

  subscriptionInfoSubmit(){
    this.registerForm.controls.personalInfo.markAllAsTouched();
    this.registerForm.controls.checkOrg.markAllAsTouched();

    if(this.registerForm.controls.personalInfo.valid && this.registerForm.controls.checkOrg.valid){

      $('#checkOrg').removeClass('show active');
      $('#cohortinfo').removeClass('show active');
      $('#subscriptioninfo').addClass('show active');
      $('#checkOrg-tab').removeClass('active');
      $('#checkOrg-tab').addClass('complete');
      $('#institute-tab').removeClass('show active')
      $('#institute').removeClass('show active')
      $('#institute-tab').addClass('complete')
      $('#cohortinfo-tab').addClass('complete');
      $('#cohortinfo-tab').removeClass('active');
      $('#subscriptioninfo-tab').removeClass('complete');
      $('#subscriptioninfo-tab').addClass('active');
      $('#personalinfo').removeClass('show active');
      $('#personalinfo-tab').removeClass('active');
      $('#confirm').removeClass('show active');
      $('#confirm-tab').removeClass('active');
    }
  }

  subscriptionInfoPrevious(){
    $('#confirm').removeClass('show active');
    $('#confirm-tab').removeClass('active');
    $('#personalinfo').removeClass('show active');
    $('#personalinfo-tab').removeClass('complete');
    $('#personalinfo-tab').removeClass('active');
    $('#checkOrg').removeClass('show active');
    $('#checkOrg-tab').removeClass('active');
    $('#institute').removeClass('show active');
    $('#institute-tab').removeClass('active');
    $('#cohortinfo').addClass('show active');
    $('#cohortinfo-tab').removeClass('complete');
    $('#cohortinfo-tab').addClass('active');
    $('#subscriptioninfo').removeClass('show active');
    $('#subscriptioninfo-tab').removeClass('complete');
    $('#subscriptioninfo-tab').removeClass('active');
  }

  personalInfoPrevious() {
    $('#cohortinfo').removeClass('show active');
    $('#subscriptionInfo').removeClass('show active');
    $('#personalinfo').removeClass('show active');
    $('#institute').addClass('show active');
    $('#personalinfo-tab').removeClass('active');
    $('#institute-tab').removeClass('complete');
    $('#institute-tab').addClass('active');
    $('#subscription-tab').removeClass('complete');
    $('#subscription-tab').removeClass('active');
    $('#checkOrg').removeClass('show active');
    $('#checkOrg-tab').removeClass('active');
    $('#confirm').removeClass('show active');
    $('#confirm-tab').removeClass('active');
  }



  cohortPrevButton(){
    $('#cohortinfo').removeClass('show active');
    $('#personalinfo').removeClass('show active');
    $('#institute').addClass('show active');
    $('#personalinfo-tab').removeClass('active');
    $('#institute-tab').removeClass('complete');
    $('#cohortinfo-tab').removeClass('active');
    $('#subscriptionInfo').removeClass('show active');
    $('#subscription-tab').removeClass('complete');
    $('#subscription-tab').removeClass('active');
    $('#institute-tab').addClass('active');
    $('#checkOrg').removeClass('show active');
    $('#checkOrg-tab').removeClass('active');
    $('#confirm').removeClass('show active');
    $('#confirm-tab').removeClass('active');

  }
  //prev (show, active)
  // $('#cohortinfo').removeClass('show active');
  // $('#personalinfo').removeClass('show active');


  personalInfoSubmit() {
    this.registerForm.controls.personalInfo.markAllAsTouched();
    this.registerForm.controls.instituteInfo.markAllAsTouched();
    this.registerForm.controls.checkOrg.markAllAsTouched();
    if (
      this.registerForm.controls.checkOrg.valid &&
      this.registerForm.controls.personalInfo.valid &&
      this.registerForm.controls.instituteInfo.valid &&
      !this.system_already_in_list
    ) {
      this.personalInfo = this.registerForm.value.personalInfo;
      this.instituteInfo = {
        ...this.instituteInfo,
        ...this.registerForm.value.checkOrg,
        ...this.registerForm.value.instituteInfo,
      };
      this.instituteInfo.is_active =
        this.instituteInfo.is_active == true ||
        this.instituteInfo.is_active == 1
          ? 1
          : 0;
      this.instituteInfo.emergency_services =
        this.instituteInfo.emergency_services == true ||
        this.instituteInfo.emergency_services == 1
          ? 1
          : 0;
      $('#personalinfo').removeClass('show active');
      $('#personalinfo-tab').removeClass('active');
      $('#personalinfo-tab').addClass('complete');
      $('#cohortinfo-tab').removeClass('active');
      $('#cohortinfo').removeClass('show active');
      $('#subscriptioninfo').removeClass('show active');
      $('#subscriptioninfo-tab').addClass('complete');
      $('#subscriptioninfo-tab').removeClass('active');
      $('#cohortinfo-tab').addClass('complete');
      $('#confirm').addClass('show active');
      $('#confirm-tab').addClass('active');
      $('#confirm-tab').removeClass('complete');
      $('#institute').removeClass('show active');
      $('#institute-tab').removeClass('active');
      $('#institute-tab').addClass('complete');
      $('#checkOrg').removeClass('show active');
      $('#checkOrg-tab').removeClass('active');
      $('#checkOrg-tab').addClass('complete');
    }
  }

  confirmInfoPrevious() {
    $('#confirm').removeClass('show active');
    $('#confirm-tab').removeClass('active');
    $('#personalinfo').removeClass('show active');
    $('#personalinfo-tab').removeClass('complete');
    $('#personalinfo-tab').removeClass('active');
    $('#subscriptioninfo').addClass('show active');
    $('#subscriptioninfo-tab').removeClass('complete');
    $('#subscriptioninfo-tab').addClass('active');
    $('#checkOrg').removeClass('show active');
    $('#checkOrg-tab').removeClass('active');
    $('#institute').removeClass('show active');
    $('#institute-tab').removeClass('active');

  }

  formatDate(date, formatted) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    if (formatted) {
      return [month, day, year].join('/');
    } else {
      return [year, month, day].join('-');
    }
  }

  registeredDateChange(date) {
    this.instituteInfo.formatted_registered_date =
      date != null ? this.formatDate(date, true) : null;
  }

  registration() {
    Swal.fire({
      icon: 'warning',
      title: 'Organization Enrollment',
      text: 'Are you sure you want to enroll this organization?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.instituteInfo.phone_number =
          this.instituteInfo.formated_phone_number.replace(/[()\- ]/g, '');
        this.instituteInfo.registered_date =
          this.instituteInfo.registered_date != null
            ? this.formatDate(this.instituteInfo.registered_date, false)
            : null;
            
            let subscription_expiry_date = "";
            if(this.registerForm.controls.subscriptionInfo.value.expiry_date !== null && this.registerForm.controls.subscriptionInfo.value.expiry_date !== '' && this.registerForm.controls.subscriptionInfo.value.expiry_date !== undefined){
              let expiry_date = new Date(this.registerForm.controls.subscriptionInfo.value.expiry_date);
              subscription_expiry_date = new Date(Date.UTC(expiry_date.getFullYear(), expiry_date.getMonth(), expiry_date.getDate())).toISOString(); 
            } 
            this.subscriptionInfo = {
              "subscription_number": this.registerForm.controls.subscriptionInfo.value.subscription_number,
              "subscription_order_id": this.registerForm.controls.subscriptionInfo.value.subscription_order_id,
              "expiry_date": subscription_expiry_date
            }
        let entity = { ...this.personalInfo, ...this.instituteInfo, ...this.subscriptionInfo };
        this.globals.isLoading = true;
        entity.is_active =
        (entity.is_active == true || entity.is_active == 1)
          ? 1
          : 0;
        this.RegistrationService.registration(entity).then(
          (data) => {
            this.globals.isLoading = false;
            let institute_id = data['data'];
            
            let timerInterval;
            Swal.fire({
              icon: 'success',
              title: 'Successfully Enrolled',
              showConfirmButton: true,
              timer: 5000,
              html: "Enrollment process completed successfully." + "<span class='close_msg'>This message box will auto close in <b></b>seconds!</span>",
              timerProgressBar: true,
              didOpen: () => {
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                  b.textContent = (Swal.getTimerLeft() / 1000).toFixed()
                }, 100)
              },
              willClose: () => {
                clearInterval(timerInterval)
              }
            }).then((result) => {
              //this.globals.isLoading = true;
              this.CookieService.set('refresh_authdata','1');
              this.AuthService.getAuthUser().then(
                (data) => {
                  //this.globals.isLoading = false;
                  if (this.globals.authData.list.length > 1) {
                    let check = 0;
                    let inactive_check = 0;
                    let total_check = 0;
                    let active_check = 0;
                    let index = 0;
                    for (let i = 0; i < this.globals.authData.list.length; i++) {
                      check++;
                      // if (this.globals.authData.list[i].institute != '' && this.globals.authData.list[i].institute.is_active == 1 && this.globals.authData.list[i].institute.id == institute_id) {
                      if (
                        this.globals.authData.list[i].role_value == 1 ||
                        this.globals.authData.list[i].role_value == 2
                      ) {
                        this.globals.authData.list[i].index_number = i;
                        this.CookieService.set(
                          'institute_details',
                          window.btoa(
                            JSON.stringify(this.globals.authData.list[i])
                          ),
                          365,
                          '/',
                          this.globals.CookieDomainUrl
                        );
                        this.globals.institute_details =
                          this.globals.authData.list[i];
                        if (
                          this.globals.institute_details.role_value == 1 ||
                          this.globals.institute_details.role_value == 2
                        ) {
                          window.location.href =
                            this.globals.baseUrl + '/manage/organization/list';
                        } else {
                          window.location.href =
                            this.globals.baseUrl + '/dashboard';
                        }
                        break;
                      }
                      if (
                        this.globals.authData.list[i].institute != '' &&
                        this.globals.authData.list[i].institute.is_active == 1 &&
                        this.globals.authData.list[i].institute.id == institute_id
                      ) {
                        index = i;
                      }
                    }
                    if (index >= 0) {
                      let i = index;
                      this.globals.authData.list[i].index_number = i;
                      this.CookieService.set(
                        'institute_details',
                        window.btoa(
                          JSON.stringify(this.globals.authData.list[i])
                        ),
                        365,
                        '/',
                        this.globals.CookieDomainUrl
                      );
                      this.globals.institute_details =
                        this.globals.authData.list[i];
                      if (
                        this.globals.institute_details.role_value == 1 ||
                        this.globals.institute_details.role_value == 2
                      ) {
                        window.location.href =
                          this.globals.baseUrl + '/manage/organization/list';
                      } else {
                        window.location.href =
                          this.globals.baseUrl + '/dashboard';
                      }
                    }
                    //this.router.navigate(['/landing-dashboard']);
                    //   if (this.globals.authData.list[i].role_value == 1 || this.globals.authData.list[i].role_value == 2) {
                    //     if (this.globals.authData.list[i].institute != '' && this.globals.authData.list[i].institute.is_active == 0) {

                    //     } else {
                    //       check++;
                    //       this.globals.authData.list[i].index_number = i;
                    //       this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[i])), 365, '/', this.globals.CookieDomainUrl);
                    //       this.globals.institute_details = this.globals.authData.list[i];
                    //       window.location.href = this.globals.baseUrl+'/dashboard';
                    //       break;
                    //     }
                    //   }
                    //   if (this.globals.authData.list[i].institute != '') {
                    //     if (this.globals.authData.list[i].institute.is_active == 0) {
                    //       inactive_check++;
                    //     } else {
                    //       index = i;
                    //       active_check++;
                    //     }
                    //     total_check++;
                    //   }
                    // }
                    // if (active_check == 1 && total_check == this.globals.authData.list.length) {
                    //   let i = index;
                    //   this.globals.authData.list[i].index_number = i;
                    //   this.CookieService.set('institute_details', window.btoa(JSON.stringify(this.globals.authData.list[i])), 365, '/', this.globals.CookieDomainUrl);
                    //   this.globals.institute_details = this.globals.authData.list[i];
                    //   this.router.navigate(['/dashboard']);
                    // } else {
                    //   if (inactive_check == total_check) {
                    //     this.router.navigate(['/landing-dashboard']);
                    //     // this.AuthService.logout()
                    //     // .then((data) => {
                    //     //   this.globals.isLoading = false;
                    //     //   window.location.href = this.globals.amsnLogoutUrl;
                    //     // },
                    //     // (error) => {
                    //     //   this.globals.isLoading = false;
                    //     //   this.globals.errorSweetAlert();
                    //     // });
                    //   } else if (check == 0) {
                    //     this.globals.institute_details = null;
                    //     this.CookieService.delete('institute_details', '/');
                    //     this.router.navigate(['/landing-dashboard']);
                    //     //this.router.navigate(['/select-organization']);
                    //   }
                    // }
                  } else if (this.globals.authData.list.length == 1) {
                    if (
                      this.globals.authData.list[0].institute != '' &&
                      this.globals.authData.list[0].institute.is_active == 0
                    ) {
                      this.router.navigate(['/landing-dashboard']);
                      // this.AuthService.logout()
                      // .then((data) => {
                      //   this.globals.isLoading = false;
                      //   window.location.href = this.globals.amsnLogoutUrl;
                      // },
                      // (error) => {
                      //   this.globals.isLoading = false;
                      //   this.globals.errorSweetAlert();
                      // });
                    } else {
                      this.globals.authData.list[0].index_number = 0;
                      this.CookieService.set(
                        'institute_details',
                        window.btoa(
                          JSON.stringify(this.globals.authData.list[0])
                        ),
                        365,
                        '/',
                        this.globals.CookieDomainUrl
                      );
                      this.globals.institute_details =
                        this.globals.authData.list[0];
                      if (
                        this.globals.institute_details.role_value == 1 ||
                        this.globals.institute_details.role_value == 2
                      ) {
                        window.location.href =
                          this.globals.baseUrl + '/manage/organization/list';
                      } else {
                        window.location.href =
                          this.globals.baseUrl + '/dashboard';
                      }
                    }
                  } else if (this.globals.authData.list.length == 0) {
                    this.router.navigate(['/landing-dashboard']);
                    // this.AuthService.logout()
                    // .then((data) => {
                    //   this.globals.isLoading = false;
                    //   window.location.href = this.globals.amsnLogoutUrl;
                    // },
                    // (error) => {
                    //   this.globals.isLoading = false;
                    //   this.globals.errorSweetAlert();
                    // });
                  }
                },
                (error) => {
                  this.globals.isLoading = false;
                  this.globals.errorSweetAlert();
                }
              );
              // this.globals.isLoading = false;
              // this.globals.sweetAlert('success','Registration Completed','Registration has been successfully.');
              // this.router.navigate(['/']);

            });

          },
          (error) => {
            this.globals.isLoading = false;
            if (error.error.code == 422) {
              if (error.error.message != undefined) {
                this.globals.sweetAlert(
                  'warning',
                  'Organization Exist',
                  'This Organization is already exist.'
                );
              }
            }
            if (error.error.code == 412) {
              if (error.error.message != undefined) {
                this.globals.sweetAlert(
                  'warning',
                  'IFMA admin cannot be a user or cohort', 
                  'IFMA admin cannot be a user or cohort.'
                );
              }
            }
            
            else {
              this.globals.errorSweetAlert();
            }
          }
        );
      }
    });
  }

  disableField(activity, field) {
    const dropdown = this.registerForm.controls.instituteInfo.get(field);
    if (activity) {
      dropdown.disable();
    } else {
      dropdown.enable();
    }
  }

  stateFilter(value) {
    this.stateList = this.stateListFilterData.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  countyFilter(value) {
    this.countyList = this.countyListFilterData.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  instituteTypeFilter(value) {
    this.instituteTypeList = this.instituteTypeListFilterData.filter(
      (s) => s.display_text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  instituteOwnershipFilter(value) {
    this.instituteOwnershipList = this.instituteOwnershipListFilterData.filter(
      (s) => s.display_text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  systemFilter(value) {
    this.systemList = this.systemListFilterData.filter(
      (s) => s.system.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  subscriptionExpiryDate: any = new Date();

  getSubscriptionExpiryDurationDays() {
    this.subscriptionService.getConfigValueByKey("SubscriptionExpiryDuration")
      .then((data: any) => {
        let resultExpiryDuration = Number(data['data'][0]['display_text']);
        if (resultExpiryDuration > 0) {
          this.subscriptionExpiryDate.setDate(this.subscriptionExpiryDate.getDate() + resultExpiryDuration);
          // console.log(this.subscriptionExpiryDate.setDate( this.subscriptionExpiryDate.getDate() + resultExpiryDuration))
        } else {
          // If the SubscripitonExpiryDate is not greater than 0 then it will update the max date of the kendo time picker to year 2100.
          this.subscriptionExpiryDate = new Date(2100, 11, 31);
        }
      },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        });
  }

  getTomorrowDate(): Date {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    
    // Set time to 00:00:00
    tomorrow.setHours(0, 0, 0, 0);
    
    return tomorrow;
    
  }
}

 export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': 'value is only whitespace' };
  };
}

