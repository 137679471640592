<div class="inner_content_block">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12">
        <div class="title_block">
          <h1 class="float-start">Organization Dashboard</h1>
          <!-- <a (click)="buyOrgLic()" class="all_btn theme_btn float-end">Buy License</a> -->
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
        <div class="dashboard_block">
          <div class="row">
            <div class="col-12 col-xl-3 col-lg-3 col-md-12 d-flex">
              <div class="number_block_dashboard">
                <a (click)="goToEditOrganization()">
                  <span class="text">{{connectedOn}}</span>Connected Since
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>
            <div class="col-12 col-xl-3 col-lg-3 col-md-12 d-flex">
              <div class="number_block_dashboard">
                <a routerLink="/manage/division/list">
                  <span>{{divisionCount}}</span>Divisions
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>
            <!-- <div class="col-12 col-xl-3 col-lg-3 col-md-12 d-flex">
              <div class="number_block_dashboard">
                <a routerLink="/manage/unit/list">
                  <span>{{unitCount}}</span>Units
                  <div class="clearfix"></div>
                </a>
              </div>
            </div> -->
            <div class="col-12 col-xl-3 col-lg-3 col-md-12 d-flex">
              <div class="number_block_dashboard">
                <a routerLink="/manage/users">
                  <span>{{userCount}}</span>Users
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>

            <div class="col-12 col-xl-3 col-lg-3 col-md-12 d-flex">
              <div class="number_block_dashboard">
                <a routerLink="/manage/cohorts">
                  <span>{{cohortCount}}</span>Cohorts
                  <div class="clearfix"></div>
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box pb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Users by Divisions</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.userChart">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-3">Below pie chart shows the distribution of users by Division based on the total number of
                  users in each Division.</p>
                <div class="clearfix"></div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.userChart">
                  <div class="height_block">
                    Data Not Available
                  </div>
                </div>
                <div id="user_chart" class="user_chart_height"></div>
              </div>
            </div>
            <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box pb-0">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Recently Connected Users</h5>
                  <a *ngIf="recentlyConnectedUsers.length > 0" class="float-end view_all no_border"
                    routerLink="/manage/users"><i class="fa fa-eye"></i> View
                    All</a>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.recentUser">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-3">Below is the list of recently connected users either by bulk upload or by connecting
                  them individually.</p>
                <div class="clearfix"></div>
                <div class="table-responsive">
                  <table class="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th class="name_width">Name</th>
                        <th class="connected_width">Connected on</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let user of recentlyConnectedUsers; let i=index;">
                        <td>{{user.user_details}}</td>
                        <td>{{user.created_at | date: 'MMMM d, yyyy | hh:mm a'}}</td>
                      </tr>
                      <tr *ngIf="recentlyConnectedUsers.length == 0">
                        <td colspan="2" style="text-align: center;">Data Not Available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Top Divisions by User count</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.divisionChart">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-3">Following Divisions are ranked from highest to lowest based on total number of users.
                </p>
                <div class="clearfix"></div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.divisionChart">
                  <div class="height_block">
                    Data Not Available
                  </div>
                </div>
                <div class="responsive_chart">
                <div id="division_chart" class="top_chart_height"></div></div>
              </div>
            </div>
            <div class="col-12 col-xl-6 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Top Units by User count</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.unitChart">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>

                <p class="pb-3">Following Units are ranked from highest to lowest based on total number of users.</p>
                <div class="clearfix"></div>
                <div class="box_data_not_found" *ngIf="dataNotAvailable.unitChart">
                  <div class="height_block">
                    Data Not Available
                  </div>
                </div>
                <div class="responsive_chart">
                <div id="unit_chart" class="top_chart_height"></div></div>
              </div>
            </div> -->
            <!-- <div class="col-12 col-xl-12 col-lg-12 col-md-12 d-flex border_line">
              <div class="dashboard_box">
                <div class="box_title">
                  <h5 class="mb-0 float-start">Top Divisions and Units by User count</h5>
                  <div class="clearfix"></div>
                </div>
                <div class="box_loader" *ngIf="loader.divisionAndUnitChart">
                  <div class="height_loader">
                    <div class="img_loader">
                      <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                      <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="pb-5">Following Divisions and Units are ranked from highest to lowest based on total number of
                  users.</p>
                <div class="clearfix"></div>
                <div class="responsive_chart">
                  <div class="org_title_chart">
                    <div class="row justify-content-center">
                      <div class="col-6 col-xl-6 col-lg-6 col-md-6">
                        <h5 class="text-center">Divisions</h5>
                      </div>
                      <div class="col-6 col-xl-6 col-lg-6 col-md-6">
                        <h5 class="text-center">Units</h5>
                      </div>
                    </div>
                  </div>
                  <div class="box_data_not_found" *ngIf="dataNotAvailable.divisionAndUnitChart">
                    <div class="height_block">
                      Data Not Available
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div id="division_unit_chart" class="top_chart_height"></div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>