<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">

                    <h1 class="float-start">Subscription Pack List</h1>
                    <button kendoButton routerLink="/manage/subscription/add" class="all_btn theme_btn float-end"
                        *ngIf="globals.permissions['add-subscription']">Add a Subscription Pack</button>

                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <div *ngIf="subscriptionList?.total>0" class="filter_block mb-3">
                    <ul>
                        <li class="blue"><i class="fa fa-comment"></i>Comments</li>
                        <li class="grey"><i class="fa fa-undo"></i>Unassign</li>
                        <li class="red"><i class="fa fa-times"></i>Deactivate</li>
                        <li class="green"><i class="fa fa-check"></i>Activate</li>
                    </ul>
                </div>


                <div class="clearfix"></div>
                <div class="loader_table">
                    <div class="box_loader" *ngIf="gridLoading">
                        <div class="height_loader">
                            <div class="img_loader">
                                <img src="{{globals.cndURL}}assets/images/loader.png" alt="" class="img-fluid" />
                                <div class="loader">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <kendo-grid [data]="subscriptionList" [pageSize]="pageSize" [skip]="skip"
                        [pageable]="subscriptionList?.total > 0 ? true : false" (pageChange)="pageChange($event)"
                        [sort]="sort" [sortable]="{
                          allowUnsort: false,
                          mode: 'single'
                          }" (sortChange)="sortChange($event)">
                        <kendo-grid-messages *ngIf="subscriptionList?.total>0" pagerItems="Subscription Packs"
                            pagerItemsPerPage="Subscription packs per page"
                            noRecords="No Subscription Packs available.">
                        </kendo-grid-messages>
                        <ng-template kendoGridToolbarTemplate>
                            <input placeholder="Search by Order Id and Total Subscriptions" kendoTextBox
                                (input)="onFilter($event.target.value)" />
                            <kendo-pager-page-sizes *ngIf="subscriptionList?.total>0"
                                [pageSizes]="globals.pagesizes"></kendo-pager-page-sizes>
                        </ng-template>
                        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                            <kendo-pager-numeric-buttons
                                [buttonCount]="globals.pageIndex"></kendo-pager-numeric-buttons>
                            <kendo-pager-next-buttons></kendo-pager-next-buttons>
                            <kendo-pager-info></kendo-pager-info>
                        </ng-template>
                        <!-- <kendo-grid-column field="institute_name" title="Organization Name"
                            [headerStyle]="{width: '80%'}" [style]="{width: '80%'}" media="(min-width: 769px)">
                        </kendo-grid-column> -->
                        <kendo-grid-column field="subscription_order_id" title="Order Id" [headerStyle]="{width: '80%'}"
                        [style]="{width: '80%'}" media="(min-width: 769px)">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.subscription_order_id != null ? dataItem.subscription_order_id : 'NA'}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="total_licenses"  title="Total Subscriptions" [headerStyle]="{width: '50%'}"
                        [style]="{width: '50%'}" media="(min-width: 769px)">
                    </kendo-grid-column>

                   

                    <kendo-grid-column field="total_license_of_users" title="Assigned to Users"[headerStyle]="{width: '50%'}"
                    [style]="{width: '50%'}" media="(min-width: 769px)">
                      <!-- <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.total_license_of_users + dataItem.assigned_by_division}}
                </ng-template> -->  
                </kendo-grid-column>

                            <!-- n -->
                <kendo-grid-column field="assigned_licenses" title="Assigned To Divisions"[headerStyle]="{width: '50%'}"
                [style]="{width: '50%'}" media="(min-width: 769px)">



                <!-- <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.assigned_licenses != null ? dataItem.assigned_licenses : '-'}}
                </ng-template> -->
            </kendo-grid-column>
            
            <!-- n -->
            <!-- <kendo-grid-column field="used_licenses" title="Assigned by Organization"[headerStyle]="{width: '50%'}"
            [style]="{width: '50%'}" media="(min-width: 769px)">
        </kendo-grid-column> -->
            <!-- n -->

        <!-- <kendo-grid-column field="assigned_by_division" title="Assigned by Division "[headerStyle]="{width: '50%'}"
        [style]="{width: '50%'}" media="(min-width: 769px)">
    </kendo-grid-column> -->

                        <kendo-grid-column field="license_expire" title="Expiry Date" [headerStyle]="{width: '50%'}"
                            [style]="{width: '50%'}" media="(min-width: 769px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.license_expire | date:'MMM d, y' }}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?"
                            [headerStyle]="{width: '40%','text-align': 'center'}" [style]="{width: '40%'}"
                            media="(min-width: 769px)" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-subscription']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <!-- <kendo-switch (valueChange)="updateStatus(dataItem,rowIndex)" [(ngModel)]="dataItem.is_active" [checked]="dataItem.is_active==1" [onLabel]="'Yes'" [offLabel]="'No'"></kendo-switch> -->
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Action" [headerStyle]="{width: '40%','text-align': 'center'}"
                            [style]="{width: '40%'}" media="(min-width: 769px)" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-subscription'] || globals.permissions['delete-subscription']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <!-- <button kendoButton (click)="edit(dataItem.id)" title="Edit" class="icon_btn grey"
                                        *ngIf="globals.permissions['edit-subscription']"><i class="fa fa-pencil"></i></button> -->
                                </div>
                                <!-- [disabled]="dataItem.comments == null" -->
                                <div kendoTooltip>
                                    <button  kendoButton (click)="getById(dataItem.id,'comment')" title="Comments"
                                        class="icon_btn blue" *ngIf="globals.permissions['edit-subscription']"><i
                                            class="fa fa-comment"></i></button>

                                    <button [disabled]="dataItem.total_licenses == null" kendoButton (click)="getById(dataItem.id,'unassign')" title="Unassign"
                                        class="icon_btn grey" *ngIf="globals.permissions['edit-subscription']"><i
                                            class="fa fa-undo"></i></button>

                                    <button *ngIf="dataItem.is_active == 1" title="Deactivate"
                                        (click)="changeStatus(dataItem.id,'Deactivate')"
                                        [disabled]="dataItem.used_licenses > 0" class="icon_btn red"><i
                                            class="fa fa-times"></i></button>

                                    <button *ngIf="dataItem.is_active == 0" title="Activate"
                                        (click)="changeStatus(dataItem.id,'Activate')" class="icon_btn green"><i
                                            class="fa fa-check"></i></button>
                                    <!-- <button kendoButton (click)="delete(dataItem)" [disabled]="dataItem.used_count>0" title="Delete" class="icon_btn red" *ngIf="globals.permissions['delete-subscription']"><i class="fa fa-trash"></i></button> -->
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <!-- <kendo-grid-column field="institute_name" title="Organization Name" width="200"
                            media="(max-width: 768px)">
                        </kendo-grid-column> -->
                        <kendo-grid-column field="subscription_order_id" title="Order Id" width="200"
                        media="(max-width: 768px)">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.subscription_order_id != null ? dataItem.subscription_order_id : 'NA'}}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="total_licenses"  title="Total Subscriptions" width="200"
                        media="(max-width: 768px)">
                    </kendo-grid-column>

                     <!-- n -->
                    <kendo-grid-column field="total_license_of_users" title="Assigned to Users"width="200"
                    media="(max-width: 768px)">
                    <!-- <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.total_license_of_users + dataItem.assigned_by_division}}
                    </ng-template> -->
                </kendo-grid-column>

                   <kendo-grid-column field="assigned_licenses" title="Assigned To Divisions" width="200"
                        media="(max-width: 768px)">
                        <!-- <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.assigned_licenses != null ? dataItem.assigned_licenses : '-'}}
                        </ng-template> -->
                    </kendo-grid-column>

                

                      <!-- n -->
            <!-- <kendo-grid-column field="used_licenses" title="Assigned by Organization" width="200"
            media="(max-width: 768px)">
        </kendo-grid-column> -->
            <!-- n -->
<!-- 
        <kendo-grid-column field="assigned_by_division" title="Assigned by Division" width="200"
        media="(max-width: 768px)">
    </kendo-grid-column> -->

        
                        <kendo-grid-column field="license_expire" title="Expiry Date" width="100"
                            media="(max-width: 768px)">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <!-- {{ dataItem.license_expire | date:'MMM d, y h:mm a' }} -->
                                {{ dataItem.license_expire | date:'MMM d, y' }}
                            </ng-template>

                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?" width="95" media="(max-width: 768px)"
                            [headerStyle]="{'text-align': 'center'}" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-subscription']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <!-- <kendo-switch (valueChange)="updateStatus(dataItem,rowIndex)" [(ngModel)]="dataItem.is_active" [checked]="dataItem.is_active==1" [onLabel]="'Yes'" [offLabel]="'No'"></kendo-switch> -->
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column title="Action" width="85" media="(max-width: 768px)"
                            [headerStyle]="{'text-align': 'center'}" [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-subscription'] || globals.permissions['delete-subscription']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <!-- <button kendoButton (click)="edit(dataItem.id)" title="Edit" class="icon_btn grey"
                                        *ngIf="globals.permissions['edit-subscription']"><i class="fa fa-pencil"></i></button> -->
                                </div>
                                <div kendoTooltip>
                                    <button kendoButton (click)="getById(dataItem.id, 'comment')" title="Comment"
                                        class="icon_btn blue"><i class="fa fa-comment"></i></button>
                                    <button [disabled]="dataItem.total_licenses == null" kendoButton (click)="getById(dataItem.id, 'unassign')" title="Unassign"
                                        class="icon_btn grey"><i class="fa fa-undo"></i></button>
                                    <button *ngIf="dataItem.is_active == 1" title="Deactivate"
                                        (click)="changeStatus(dataItem.id,'Deactivate')"
                                        [disabled]="dataItem.used_licenses > 0" class="icon_btn red"><i
                                            class="fa fa-times"></i></button>

                                    <button *ngIf="dataItem.is_active == 0" title="Activate"
                                        (click)="changeStatus(dataItem.id,'Activate')" class="icon_btn green"><i
                                            class="fa fa-check"></i></button>
                                    <!-- <button kendoButton (click)="delete(dataItem)" [disabled]="dataItem.used_count>0" title="Delete" class="icon_btn red" *ngIf="globals.permissions['delete-subscription']"><i class="fa fa-trash"></i></button> -->
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>


<kendo-dialog *ngIf="dialogOpened" [minWidth]="550" [width]="550" (close)="close();">
    <kendo-dialog-titlebar>
        <h5 class="modal-title" id="switch_org_modalLabel">Unassign Subscriptions</h5>
    </kendo-dialog-titlebar>
    <div kendoDialogContainer>

        <!-- <div class="row row-cols-2 row-cols-lg-2 g-2 g-lg-2">
            <div class="col">
                <div class="p-3 border bg-light d-flex align-items-center justify-content-between">Total Licenses
                    <span>{{ total_licenses }}</span>
                </div>
            </div>
            <div class="col">
                <div class="p-3 border bg-light d-flex align-items-center justify-content-between">Used
                    License<span>{{ used_licenses }}</span></div>
            </div>
        </div> -->



        <form class="k-form" #form="ngForm">

            <kendo-formfield>
                <label><span>*</span>No. of subscription you can unassign ({{availableLicense}})</label>
                <kendo-numerictextbox format="##" [min]="1" name="subscription_number" [(ngModel)]="subscriptionNumber"
                    #subscription="ngModel" (valueChange)="subscriptionValidationErr()">
                </kendo-numerictextbox>

                <kendo-formerror *ngIf="subsValidationErr">
                    Please enter no. of subscription.
                </kendo-formerror>

                

                <div class="k-form-error" *ngIf="subsValidationErr">
                    Only unassigned subscriptions can be removed. Current unassigned subscriptions: {{total_licenses -
                    used_licenses - assigned_licenses}}.
                </div>

                <div class="k-form-error" *ngIf="negativeValErr">
                    Please enter a valid number.
                </div>
            </kendo-formfield>

        </form>


        <div class="footer_button text-end">
            <button [disabled]="negativeValErr || unassignButtonDisabled || subscriptionNumber == 0 || subscriptionNumber == null"
                (click)="update(this.form.value)" type="button" class="all_btn theme_btn">Unassign</button>
            <button (click)="dialogOpened = false;" type="button" class="all_btn theme_btn">Cancel</button>
        </div>
    </div>

</kendo-dialog>


<kendo-dialog *ngIf="commentdDialogOpened" [minWidth]="550" [width]="550" (close)="close();">
    <kendo-dialog-titlebar>
        <h5 class="modal-title" id="switch_org_modalLabel">Comments</h5>
    </kendo-dialog-titlebar>
    <div kendoDialogContainer>

        <!-- <div class="row row-cols-2 row-cols-lg-2 g-2 g-lg-2">
            <div class="col">
                <div class="p-3 border bg-light d-flex align-items-center justify-content-between">Total Licenses
                    <span>{{ total_licenses }}</span>
                </div>
            </div>
            <div class="col">
                <div class="p-3 border bg-light d-flex align-items-center justify-content-between">Used
                    License<span>{{ used_licenses }}</span></div>
            </div>
        </div> -->



        <form class="k-form" #form="ngForm">

            <kendo-formfield>
                <!-- <label><span>*</span>No. of subscription you can unassign ({{availableLicense}})</label> -->
                <!-- <kendo-numerictextbox format="##" [min]="1" name="subscription_number" [(ngModel)]="subscriptionNumber"
                    #subscription="ngModel" (valueChange)="subscriptionValidationErr()">
                </kendo-numerictextbox> -->

                <kendo-textarea name="comment" [(ngModel)]="comment" #comments="ngModel">
                </kendo-textarea>

                <!-- <textarea name="" id=""></textarea> -->

                <!-- <kendo-formerror *ngIf="subsValidationErr">
                    Please enter no. of subscription.
                </kendo-formerror> -->

                

                <!-- <div class="k-form-error" *ngIf="subsValidationErr">
                    Only unassigned subscriptions can be removed. Current unassigned subscriptions: {{total_licenses -
                    used_licenses - assigned_licenses}}.
                </div> -->

                <!-- <div class="k-form-error" *ngIf="negativeValErr">
                    Please enter a valid number.
                </div> -->
            </kendo-formfield>

        </form>


        <div class="footer_button text-end">
            <button 
                (click)="update(this.form.value)" type="button" class="all_btn theme_btn">Submit</button>
            <button (click)="commentdDialogOpened = false;" type="button" class="all_btn theme_btn">Cancel</button>
        </div>
    </div>

</kendo-dialog>