<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start">
                        <ng-template [ngIf]="id==0" [ngIfElse]="updateTitle"> Add Subscription Pack
                        </ng-template>
                        <ng-template #updateTitle> Update Subscription </ng-template>
                    </h1>

                    <button *ngIf="globals.permissions['subscription-list']" kendoButton
                        routerLink="/manage/subscription/list" class="all_btn theme_btn float-end">List of
                        Subscription Packs</button>

                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <form class="k-form" [formGroup]="form">
                    <fieldset class="k-form-fieldset">
                        <div class="grey_form_block">
                            <div class="row">
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span> Select an Organization</label>
                                        <kendo-dropdownlist [defaultItem]="defaultItem" #institute_id formControlName="institute_id"
                                            [data]="instituteList" [textField]="'institute_name'" [valueField]="'id'"
                                            [valuePrimitive]="true" [filterable]="true"
                                            (filterChange)="instituteFilter($event)" (valueChange)="onOrgChange($event)">
                                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                                <span title="{{ dataItem.institute_name }}">{{ dataItem.institute_name
                                                    }}</span>
                                            </ng-template>
                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                <h6>NO ORGANIZATION FOUND</h6>
                                            </ng-template>
                                        </kendo-dropdownlist>
                                        <kendo-formerror *ngIf="form.controls.institute_id.errors?.required">
                                            Please select organization.
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>

                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label> Select a Division</label>
                                        <kendo-dropdownlist [defaultItem]="defaultDept" #department_id formControlName="department_id"
                                            [data]="deptList" textField="department" valueField="id" 
                                            [valuePrimitive]="true" [filterable]="true"
                                            (filterChange)="deptFilter($event)">
                                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                                <span title="{{ dataItem.department }}">{{ dataItem.department
                                                    }}</span>
                                            </ng-template>
                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                <h6>NO DIVISION FOUND</h6>
                                            </ng-template>
                                        </kendo-dropdownlist>
                                        <!-- <kendo-formerror *ngIf="form.controls.department_id.errors?.required">
                                            Please select a division.
                                        </kendo-formerror> -->
                                    </kendo-formfield>
                                </div>

                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield [ngClass]="{'k-form-field-error': duplicate_name}">
                                        <label><span>*</span>No. of Subscription</label>
                                        <kendo-numerictextbox format="##" [min]="1" formControlName="subscription_number" #subscription
                                            [value]="form.controls.subscription_number"></kendo-numerictextbox>
                                        <!-- <input formControlName="subscription_number" #subscription minlength="2"
                                            maxlength="100" kendoTextBox (keyup)="duplicate_name = false" /> -->
                                        <kendo-formerror *ngIf="form.controls.subscription_number.errors?.required">
                                            Please enter no. of subscription.
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>

                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield [ngClass]="{'k-form-field-error': duplicate_name}">
                                        <label><span>*</span>Order Id</label>
                                        <input formControlName="subscription_order_id" #orderid minlength="2"
                                            maxlength="100" kendoTextBox (keyup)="duplicate_name = false" />
                                        <kendo-formerror *ngIf="form.controls.subscription_order_id.errors?.required">
                                            Please enter Order Id.
                                        </kendo-formerror>
                                        <kendo-formerror *ngIf="!form.controls.subscription_order_id.errors?.required && form.controls.subscription_order_id.errors?.whitespace">
                                           Only space is not allowed
                                        </kendo-formerror>
                                        <kendo-formerror *ngIf="form.controls.subscription_order_id.errors?.pattern">
                                            Invalid Order ID. Please use only letters, numbers, &commat;, hyphen, and underscore.
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>


                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span>Expiry Date</label>

                                        <kendo-datepicker [min]="getTomorrowDate()" [max]="subscriptionExpiryDate"
                                            formControlName="expiry_date" placeholder="Expiry Date">
                                        </kendo-datepicker>
                                        
                                        <kendo-formerror *ngIf="form.controls.expiry_date.errors?.required">
                                            Please enter expiry date.
                                        </kendo-formerror>

                                        <kendo-formerror *ngIf="form.controls.expiry_date.errors?.maxError">
                                            Please enter valid expiry date.
                                        </kendo-formerror>
                                    </kendo-formfield>

                                </div>

                                <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                                    <kendo-formfield>
                                        <label> Comment</label>
                                        <kendo-editor formControlName="comment">
                                            <kendo-toolbar>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorItalicButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnderlineButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignRightButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorIndentButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorOutdentButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnlinkButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                <!-- <kendo-toolbar-button kendoEditorViewSourceButton>
                                                </kendo-toolbar-button> -->
                                            </kendo-toolbar>
                                        </kendo-editor>
                                    </kendo-formfield>
                                </div>

                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span> Is Active?</label>
                                        <kendo-switch formControlName="is_active" #is_active [onLabel]="'Yes'"
                                            [offLabel]="'No'">
                                        </kendo-switch>
                                    </kendo-formfield>
                                </div>

                            </div>
                        </div>

                        <div class="text-center">
                            <button kendoButton (click)="addUpdate()" [primary]="true" class="all_btn theme_btn">
                                <ng-template [ngIf]="id==0" [ngIfElse]="updateButton"> Add </ng-template>
                                <ng-template #updateButton> Update </ng-template>
                            </button>
                            <button type="button" kendoButton routerLink="/manage/subscription/list" [primary]="true"
                                class="all_btn themeoption_btn" *ngIf="globals.permissions['subscription-list']">
                                Cancel
                            </button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>