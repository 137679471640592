<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start">
                        <ng-template [ngIf]="id==0" [ngIfElse]="updateTitle"> Add a Coupon
                        </ng-template>
                        <ng-template #updateTitle> Update a Coupon </ng-template>
                    </h1>
                    
                    <button *ngIf="globals.permissions['coupon-list']" kendoButton routerLink="/manage/coupon/list" class="all_btn theme_btn float-end">List of
                        Coupons</button>

                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <form class="k-form" [formGroup]="form">
                    <fieldset class="k-form-fieldset">
                        <div class="grey_form_block">
                            <div class="row">
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield  [ngClass]="{'k-form-field-error': duplicate_name}">
                                        <label><span>*</span>Name</label>
                                        <input formControlName="name" #name minlength="2" maxlength="100" kendoTextBox
                                            (keyup)="duplicate_name = false" />
                                        <kendo-formerror *ngIf="form.controls.name.errors?.required">
                                            Please enter coupon name.
                                        </kendo-formerror>

                                        <kendo-formerror *ngIf="form.controls.name.errors?.whitespace && !form.controls.name.errors?.required">
                                            only space is not allowed
                                        </kendo-formerror>
                                        <!-- <kendo-formerror *ngIf="form.controls.display_text.errors?.minlength">
                                            Type must be at least 2 characters long.
                                        </kendo-formerror>
                                        <div class="k-form-error" *ngIf="duplicate_name">
                                            Type is already taken.
                                        </div>  -->
                                    </kendo-formfield>
                                </div>


                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span>Expiry Date</label>

                                        <kendo-datepicker [min]="currentDate" formControlName="expiry_date" placeholder="Expiry Date">
                                        </kendo-datepicker>
                                        <kendo-formerror *ngIf="form.controls.expiry_date.errors?.required">
                                            Please enter expiry date.
                                        </kendo-formerror>
                                    </kendo-formfield>

                                </div>

                                <!-- <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span> Discount Type?</label>
                                        <kendo-switch formControlName="coupon_type_id" #coupon_type_id
                                            (valueChange)="onChange($event)" [(ngModel)]="discount_type_value"
                                            [onLabel]="'%'" [offLabel]="'$'">
                                        </kendo-switch>
                                    </kendo-formfield>
                                </div> -->

                               

                                <div class="col-12 col-xl-6 col-lg-6 col-md-12">
                                    <kendo-formfield>
                                      <label class="mb-2"><span>*</span> Discount Type</label>
                                      <div class="radio_box">
                                        <input
                                          type="radio"
                                          value="261"
                                          id="amount"
                                          formControlName="coupon_type_id"
                                          (change)="onChange('261')"
                                          kendoRadioButton
                                        />
                                        <label for="amount">Amount</label>
                                      </div>
                                      <div class="radio_box">
                                        <input
                                          type="radio"
                                          value="260"
                                          id="percentage"
                                          formControlName="coupon_type_id"
                                          (change)="onChange('260')"
                                          kendoRadioButton
                                        />
                                        <label for="percentage">Percentage</label>
                                      </div>
                                    </kendo-formfield>
                                  </div>



                               

                                <div *ngIf="discount_type == '260'" class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield [ngClass]="{'k-form-field-error': duplicate_name}">
                                        <label><span>*</span>Discount Percentage (%)</label>
                                        <input type="number" formControlName="pervalue" #value minlength="2" max="100" kendoTextBox
                                            (keyup)="duplicate_name = false" />
                                        
                                        <kendo-formerror *ngIf="form.controls.pervalue.errors?.required">
                                            Please enter discount percentage.
                                        </kendo-formerror>

                                        <kendo-formerror *ngIf="form.controls.pervalue.errors?.max">
                                            Percent value cannot exceed 100%.
                                        </kendo-formerror>
                                        <kendo-formerror *ngIf="form.controls.pervalue.errors?.whitespace && !form.controls.pervalue.errors?.required">
                                            only space is not allowed
                                        </kendo-formerror>
                                        <!-- <kendo-formerror *ngIf="form.controls.display_text.errors?.minlength">
                                            Type must be at least 2 characters long.
                                        </kendo-formerror>
                                        <div class="k-form-error" *ngIf="duplicate_name">
                                            Type is already taken.
                                        </div> -->
                                    </kendo-formfield>
                                </div>


                                <div *ngIf="discount_type == '261'" class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield [ngClass]="{'k-form-field-error': duplicate_name}">
                                        <label><span>*</span>Discount Amount (USD)</label>
                                        <kendo-numerictextbox
                                        format="c0"
                                        [step]="1"
                                        [min]="2"
                                        formControlName="amntvalue"
                                      ></kendo-numerictextbox>
                                        <!-- <input formControlName="value" #value minlength="2" maxlength="100" kendoTextBox
                                            (keyup)="duplicate_name = false" /> -->
                                        <kendo-formerror *ngIf="form.controls.amntvalue.errors?.required">
                                            Please enter discount amount.
                                        </kendo-formerror>
                                        <kendo-formerror *ngIf="form.controls.amntvalue.errors?.whitespace && !form.controls.value.errors?.required">
                                            only space is not allowed
                                        </kendo-formerror>
                                        <!-- <kendo-formerror *ngIf="form.controls.display_text.errors?.minlength">
                                            Type must be at least 2 characters long.
                                        </kendo-formerror>
                                        <div class="k-form-error" *ngIf="duplicate_name">
                                            Type is already taken.
                                        </div> -->
                                    </kendo-formfield>
                                </div>


                               


                                <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                                    <kendo-formfield>
                                        <label> Description</label>
                                        <kendo-editor formControlName="description">
                                            <kendo-toolbar>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorItalicButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnderlineButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignRightButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorIndentButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorOutdentButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnlinkButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                <!-- <kendo-toolbar-button kendoEditorViewSourceButton>
                                                </kendo-toolbar-button> -->
                                            </kendo-toolbar>
                                        </kendo-editor>
                                    </kendo-formfield>
                                </div>

                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span> Is Active?</label>
                                        <kendo-switch formControlName="is_active" #is_active [onLabel]="'Yes'"
                                            [offLabel]="'No'">
                                        </kendo-switch>
                                    </kendo-formfield>
                                </div>

                            </div>
                        </div>

                        <div class="text-center">
                            <button kendoButton (click)="addUpdate()" [primary]="true" class="all_btn theme_btn">
                                <ng-template [ngIf]="id==0" [ngIfElse]="updateButton"> Add </ng-template>
                                <ng-template #updateButton> Update </ng-template>
                            </button>
                            <button type="button" kendoButton routerLink="/manage/coupon/list"
                                [primary]="true" class="all_btn themeoption_btn"
                                *ngIf="globals.permissions['coupon-list']">
                                Cancel
                            </button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>